<template lang="pug">
  div.mt-2
    v-overlay(
      :value="voucherGetting"
    )
      v-progress-circular(
        indeterminate
        size="64"
        color="white"
      )
    v-row
      v-col(cols="12")
        v-btn(
          color="green"
          dark
          dense
          @click="storePtCashVoucher"
          :loading="ptCashVoucherStorePosting"
        )
          span Submit PT
      v-col(cols="4")
        v-toolbar(
          dark
          color="purple"
          dense
          flat
          :loading="ptCashVoucherStorePosting"
        )
          span PT Voucher Form
          v-spacer
          v-btn(
            dark
            color="green"
            dense
            @click="createSupplier = true"
          ) New Supplier
        v-card
          v-container
            v-row
              v-col(cols="6")
                v-text-field(
                  label="Voucher"
                  :value="voucherData.voucher_no"
                  hide-details="auto"
                )
              v-col(cols="6")
                v-autocomplete(
                  label="Pay To"
                  item-value="id"
                  item-text="name"
                  v-model="payToObject"
                  :search-input.sync="payToListSearch"
                  :items="payToListList"
                  :loading="payToListGetting"
                  clearable
                  :error-messages="ptCashVoucherStorePostErrors.pay_to"
                  no-filter
                  return-object
                  hide-details="auto"
                )
                  template(v-slot:item="{ item }")
                    span.primary--text {{ item.id }}
                    small.blue-grey--text.ml-2 {{ item.name }}
                    small.blue-grey--text.ml-2 ({{ item.type }})
              v-col(cols="6")
                v-text-field(
                  label="Address"
                  v-model="address"
                  hide-details="auto"
                  :error-messages="ptCashVoucherStorePostErrors.address"
                )
              v-col(cols="6")
                v-text-field(
                  label="Paid By"
                  hide-details="auto"
                  :value="voucherData.paid_by ? voucherData.paid_by.description : null"
                  readonly
                )
              v-col(cols="6")
                v-text-field(
                  label="Date"
                  type="date"
                  v-model="date"
                  hide-details="auto"
                  :error-messages="ptCashVoucherStorePostErrors.date"
                )
              v-col(cols="6")
                v-text-field(
                  label="Balance"
                  hide-details="auto"
                  :value="(voucherBalance - totalAmount).phpFormat()"
                  readonly
                )
      v-col(cols="2")
        v-toolbar(
          dense
          dark
          color="purple"
          flat
        )
          span PT Breakdown Form
        v-card
          v-container
            v-row
              v-col(cols="12")
                v-switch(
                  label="Revolving Funds"
                  hide-details="auto"
                  v-model="revolvingFunds"
                )
                v-autocomplete(
                  label="Account Code"
                  v-model="accountCodeId"
                  :items="accountCodes"
                  item-value="account_code.id"
                  item-text="account_code.description"
                  hide-details="auto"
                  :error-messages="ptCashVoucherStorePostErrors.account_code_id"
                )
                  template(v-slot:item="{ item }")
                    span.primary--text {{ item.account_code.account_code }}
                    small.ml-2.blue-grey--text {{ item.account_code.description }}
              v-col(cols="12")
                v-text-field(
                  label="Description"
                  v-model="description"
                  hide-details="auto"
                  :error-messages="voucherDetailVerifyPostErrors.description"
                )
              v-col(cols="12")
                v-text-field(
                  label="Amount"
                  type="number"
                  v-model.number="amount"
                  hide-details="auto"
                  :error-messages="voucherDetailVerifyPostErrors.amount"
                )
              v-col(cols="12")
                v-btn(
                  color="green"
                  dark
                  block
                  @click="addDetail"
                  :loading="voucherDetailVerifyPosting"
                )
                  span add
      v-col(cols="6")
        v-toolbar(
          dark
          flat
          dense
          color="purple"
        )
          span PT Breakdown
        v-card
          v-container
            v-simple-table(dense)
              template(v-slot:default)
                thead
                  tr
                    th Item
                    th Account Code
                    th Descrition
                    th Amount
                    th Config
                tbody
                  tr(v-for="(item, index) in details" :key="index")
                    td {{ index + 1 }}
                    td {{ accountCodeCode(item.account_code_id) }}
                    td {{ item.description }}
                    td {{ (item.amount || 0).phpFormat() }}
                    td
                      v-btn(
                        color="error"
                        text
                        small
                        dense
                        @click="removeItem(index)"
                      )
                        v-icon mdi-delete
                  tr
                    th(colspan="3") Total
                    td {{ totalAmount.phpFormat() }}
    v-dialog(
      v-model="createSupplier"
      width="1200px"
      persistent
    )
      v-card
        v-toolbar(
          color="primary"
          dark
          dense
          tile
          flat
        )
          v-icon(
            @click="createSupplier = false"
          ) mdi-close
        v-container
          create-supplier
</template>
<script>
import voucherRepository from '@/repositories/voucher.repository'
import ptCashVoucherRepository from '@/repositories/pt-cash-voucher.repository'
import accountCodeRepository from '@/repositories/account-code.repository'
import voucherDetailRepository from '@/repositories/voucher-detail.repository'
import { requestVars } from '@/libs/api-helper.extra'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'

const [voucherEditGetVars, voucherEditGetVarNames] = requestVars({ identifier: 'voucher', data: 'voucherData' })
const [payToListGetVars, payToListGetVarNames] = requestVars({ identifier: 'pay-to-list' })
const [voucherDetailVerifyVars, voucherDetailVerifyVarNames] = requestVars({ identifier: 'voucher-detail-verify', hasData: false, request: 'post' })
const [ptCashVoucherStoreVars, ptCashVoucherStoreVarNames] = requestVars({ identifier: 'pt-cash-voucher-store', hasData: false, request: 'post' })
const [accountCodeIndexVars, accountCodeIndexVarNames] = requestVars({ identifier: 'account-code' })

const payToListHandler = new VueRequestHandler(null, payToListGetVarNames)
const voucherEditGetHandler = new VueRequestHandler(null, voucherEditGetVarNames)
const voucherDetailVerifyHandler = new VueRequestHandler(null, voucherDetailVerifyVarNames)
const ptCashVoucherStoreHandler = new VueRequestHandler(null, ptCashVoucherStoreVarNames)
const accountCodeIndexHandler = new VueRequestHandler(null, accountCodeIndexVarNames)

const inputVars = () => ({
  payToObject: null,
  address: null,
  date: Window.getCurrentDate(),
  details: [],
})

const detailsInputVars = () => ({
  accountCodeId: null,
  description: null,
  amount: null,
})

const accountCodeParents = accountCodes => {
  const cache = {}
  return accountCodes.reduce((result, item) => {
    const parentId = item.account_code.parent_id
    if (cache[parentId]) return result
    result.push(parentId)
    return result
  }, [])
}

export default {
  name: 'CreatePettyCashVouchers',
  components: {
    // createPtCashVoucher: () => import('./Form'),
    createSupplier: () => import('@/views/admin/suppliers/Create'),
  },
  data () {
    return {
      ...voucherEditGetVars,
      ...voucherDetailVerifyVars,
      ...payToListGetVars,
      ...accountCodeIndexVars,
      ...inputVars(),
      payToListSearch: null,
      revolvingFunds: false,
      ...detailsInputVars(),
      ...ptCashVoucherStoreVars,
      createSupplier: false,
    }
  },
  created () {
    this.getVoucherData()
    this.getPayToList()
  },
  computed: {
    totalAmount () {
      return this.details.reduce((a, b) => a + b.amount, 0)
    },
    voucherBalance () {
      return this.accountCodes.reduce((total, item) => total + item.balance, 0) - this.revolvingFundDisbursedAmount
    },
    revolvingFundDisbursedAmount () {
      return this.voucherData?.revolving_fund?.disbursed_amount || 0
    },
    payToPerson () {
      return this.payToListList.find(item => item.id === this.payTo)
    },
    accountCodes () {
      const accountCodes = this.voucherData?.account_codes || []
      const accountCodeCache = accountCodes.reduce((result, item) => {
        result[item.account_code.id] = item
        return result
      }, {})
      const siblingCodes = (() => {
        if (!this.revolvingFunds) return []
        return this.accountCodeList
          .filter(item => !accountCodeCache[item.id])
          .map(item => ({
            account_code: item,
            amount: 0,
            balance: 0,
            disbursed_amount: null,
          }))
      })()
      return [...accountCodes, ...siblingCodes].sort((a, b) => a.account_code.account_code - b.account_code.account_code)
    },
    voucherId () {
      return this.$route.params.voucherId
    },
    payToType () {
      return this.payToObject ? this.payToObject.type : null
    },
    payTo () {
      return this.payToObject ? this.payToObject.id : null
    },
  },
  watch: {
    payTo () {
      this.updateAddress()
    },
    payToListSearch () {
      this.getPayToList()
    },
  },
  methods: {
    accountCodeCode (id) {
      const data = this.accountCodes.find(item => item.account_code.id === id)
      if (!data) return null
      return data.account_code.account_code
    },
    addDetail () {
      const data = Window.objectChangeKeyCase({
        description: this.description,
        amount: this.amount,
        accountCodeId: this.accountCodeId,
      }, 'camelToSnakeCase')
      const handler = voucherDetailVerifyHandler
      const repository = voucherDetailRepository.verify
      handler.setVue(this)
      handler.execute(repository, [data], ({ data }) => {
        this.details.push(data)
        this.resetDetailForm()
      })
    },
    resetDetailForm () {
      const defaults = detailsInputVars()
      Object.keys(defaults)
        .forEach(key => {
          this[key] = defaults[key]
        })
    },
    removeItem (index) {
      this.details.splice(index, 1)
    },
    getPayToList () {
      const handler = payToListHandler
      const repository = voucherRepository.payToList
      const params = {
        search: this.payToListSearch,
      }
      handler.setVue(this)
      handler.execute(repository, [params])
    },
    getAccountCodes (parents = []) {
      const handler = accountCodeIndexHandler
      const repository = accountCodeRepository.children
      const params = { parents: parents.join(',') }
      handler.setVue(this)
      handler.execute(repository, [params])
    },
    async getVoucherData () {
      const handler = voucherEditGetHandler
      const repository = voucherRepository.edit
      const { voucherId } = this.$route.params
      handler.setVue(this)
      const { data } = await handler.execute(repository, [voucherId])
      const parents = accountCodeParents(data.account_codes)
      this.getAccountCodes(parents)
      this.costCenterId = data.details.first().cost_center_id
    },
    getInputVars () {
      const excluded = item => !['payToObject'].includes(item)
      return Object.keys(inputVars())
        .filter(excluded)
        .concat(['voucherId', 'payTo', 'payToType'])
        .reduce((result, key) => {
          result[key.camelToSnakeCase()] = this[key]
          return result
        }, {})
    },
    storePtCashVoucher () {
      this.$confirm('Are you sure you want to submit this pt cash?')
        .then(result => {
          if (!result) return
          const handler = ptCashVoucherStoreHandler
          const repository = ptCashVoucherRepository.store
          const data = this.getInputVars()
          handler.setVue(this)
          handler.execute(repository, [data], () => { this.$router.push(`/vouchers/${this.voucherId}/petty-cash`) })
        })
    },
    updateAddress () {
      const { payToPerson } = this
      if (!payToPerson) {
        this.address = null
        return
      }
      this.address = payToPerson.address
    },
  },
}
</script>